import { BackendNewShare } from 'utils/backendTypes';
import { Share } from 'context/customer-context/types';
import { returnBooleanForBackend } from './index';

export const serializerShareToBackend = (share: Share): BackendNewShare => {
  const allocation = Number(share.allocation?.replace(/,/g, '.'));

  return {
    id: share.id || undefined,
    allocation,
    parent: share.parent || undefined,
    side_deal:
      share.sideDeal !== undefined ? returnBooleanForBackend(share.sideDeal, true) : undefined,
  };
};
